<template>
  <v-responsive class="error-occured fill-height">
    <v-card class="d-flex flex-column justify-center fill-height w-100">
      <v-card-title class="404-header fill-height">
        <label>
          <img src="../assets/unautorized.svg" alt="" />
          <h3 class="mt-4">
            {{ $t("unAuthorized") }}
          </h3>
        </label>
      </v-card-title>
    </v-card>
  </v-responsive>
</template>



<script>
export default {
  name: "UnAuthorized",
};
</script>